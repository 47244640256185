<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">

        <validated-input class="col-12" label="Name" name="Name" v-model="model.name"
                     :rules="rules.name" :disabled="loading"/>

        <validated-vue-select class="col-12" label="Type" name="Type" v-model="model.type"
                     :rules="rules.type" :disabled="loading" :options="typeOptions" />

        <validated-input class="col-12" label="Amount" name="Amount" type="number" v-model="model.amount"
                    :rules="rules.amount" :disabled="loading"/>

        <btn class="trn" size="sm" icon="fa fa-save" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'AddProject',

    data () {
        return {
            addUrl : urls.project.addEdit,
            rules  : {
                name : {
                    required : true
                },
                type : {
                    required : true
                },
                amount : {
                    required : true
                }
            },
            typeOptions : [
                { label : 'Skill Training & Livelihood', value : 'Skill Training & Livelihood' },
                { label : 'Education', value : 'Education' },
                { label : 'Healthcare', value : 'Healthcare' }
            ]
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Project..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
